import React, { useState, useEffect } from 'react';

const AnnouncementBar = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const isAnnouncementClosed = localStorage.getItem('announcementClosed');
        if (isAnnouncementClosed !== 'true') {
            setIsVisible(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('announcementClosed', 'true');
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="bg-yellow-300 py-2 px-8 relative flex justify-center items-center text-center">
            {children}
            <button
                onClick={handleClose}
                className="absolute top-1 right-2 text-2xl bg-transparent border-none cursor-pointer"
            >
                &#10005; {/* Unicode character for X */}
            </button>
        </div>
    );
};

export default AnnouncementBar;
