import React from 'react';
import Spinner from './Spinner';

function LoadingSpinner() {
    return (
        <div className="flex justify-center items-center h-screen w-screen bg-1a0044 absolute top-0 left-0 bg-[var(--primary)] z-50">
            <Spinner></Spinner>
        </div>
    );
}

export default LoadingSpinner;
