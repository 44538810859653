module.exports = {
    locales: ['en', 'da'],
    defaultLocale: 'da',
    pages: {
        '*': ['common'],
        '/': ['home'],
        '/compensation': ['compensation'],
        '/passport-control': ['passport-control']
    },
    namespace: 'common'
};
