import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import React from 'react';

const Footer = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <footer className="p-4 border-t-2 border-solid bg-neutral-100 relative transition-all duration-1000 text-[var(--primary)] mt-auto">
                <div className="container m-auto flex flex-col text-center items-center">
                    <a
                        href="https://www.mortendlaursen.dk/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="umami--click--redirect-to-mortendlaursen"
                        data-umami-event="redirect-to-mortendlaursen"
                    >
                        {t('common:made_with')}&nbsp;
                        <span className="underline">Morten</span>
                    </a>
                    <div className="flex flex-col">
                        <a
                            href="https://www.buymeacoffee.com/mortendl"
                            target="_blank"
                            rel="noreferrer"
                            className={
                                'umami--click--donation-clicked text-center my-4'
                            }
                            data-umami-event="redirect-to-mortendlaursen"
                        >
                            <Image
                                src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                                alt="Buy Me A Coffee"
                                className="h-10 m-auto"
                                width={145}
                                height={40}
                                priority={false}
                            />
                        </a>
                        <small>
                            <span className="font-bold underline">100%</span>
                            {' ' + t('common:rainforrest')}
                        </small>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
